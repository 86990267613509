import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { memo } from 'react'

export interface Props {
  variant?: Variant
}
export const Separator = memo(function Separator({
  variant = 'default',
}: Props) {
  return <Container variant={variant} />
})

const Container = styled.div<ContainerProps>`
  width: 0.25rem;
  margin: 7.5rem auto 0;

  ${({ variant }) => {
    switch (variant) {
      case 'contact':
        return css`
          margin: 7.5rem auto;

          @media (max-width: 1199px) {
            margin: 5rem auto;
          }
        `
    }
  }}

  &:after {
    content: '';
    display: block;
    width: 0.0625rem;
    height: 5rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    transform: translateX(0.125rem);
  }

  @media (max-width: 1199px) {
    margin-top: 5rem;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'contact'
