import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'

export interface Props {
  address?: string
  email?: string
  phone?: string
  title?: string
}

export const ContactsIntro = memo(function ContactsIntro({
  address,
  email,
  phone,
  title,
}: Props) {
  return (
    <Container>
      <Wrapper>
        {title ? (
          <FadeInUp>
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}
        <FadeInUp>
          {' '}
          <Contacts>
            {address ? (
              <Address>
                <img
                  src="/icon_contacts_address.svg"
                  alt="Address"
                  width="33"
                  height="33"
                />
                {address}
              </Address>
            ) : null}
            {phone ? (
              <Anchor
                href={`tel:${phone}`}
                onClick={() => {
                  if (isMobile) {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Phone Number',
                      })
                  }
                }}
              >
                <img
                  src="/icon_contacts_phone.svg"
                  alt="Phone"
                  width="33"
                  height="33"
                />
                {phone}
              </Anchor>
            ) : null}
            {email ? (
              <Anchor
                href={`mailto:${email}`}
                target="_blank"
                onClick={() => {
                  typeof window.gtag !== 'undefined' &&
                    window.gtag('event', 'Click', {
                      event_category: 'Website',
                      event_label: 'Email Address',
                    })
                }}
              >
                <img
                  src="/icon_contacts_email.svg"
                  alt="Email"
                  width="33"
                  height="33"
                />
                {email}
              </Anchor>
            ) : null}
          </Contacts>
        </FadeInUp>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem 11.125vw 0;

  @media (max-width: 1199px) {
    margin: 5rem 1.5625rem 0;
  }
`

const Wrapper = styled.div`
  max-width: 62.125rem;
  margin: 0 auto;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 2.8125rem;
  letter-spacing: 0.02em;
  line-height: 3.4375rem;
  text-align: center;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1.875rem;
    line-height: 2.1875rem;
  }
`

const Contacts = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 5rem;
  color: ${({ theme }) => theme.colors.variants.primaryDark};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.6875rem;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin-top: 4.25rem;
  }
`

const Address = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 15rem;
  text-align: center;

  img {
    margin-bottom: 1.25rem;
  }
`

const Anchor = styled.a`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    bottom: -0.3125rem;
    left: 0;
    width: 0;
    height: 0.0938rem;
    background: ${({ theme }) => theme.colors.variants.primaryDark};
    transition: width 0.2s ease-in-out;
  }

  &:hover::before {
    width: 100%;
  }

  img {
    margin-bottom: 1.25rem;
  }

  @media (max-width: 768px) {
    margin-top: 3.25rem;
  }
`
